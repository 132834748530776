<template>
    <div>
        <img class="example-headImg" src="../../assets/image/selected/03-2-5banner.png"/>
        <div class="head-explainBox">
            <div class="head-explainBox-title1">Dashboard数据实施</div>
            <div class="head-explainBox-content1">全球某知名乳制品公司</div>
            <div class="head-explainBox-line"></div>
            <div class="head-explainBox-title2">项目介绍</div>
            <div class="head-explainBox-content2">近几年，客户公司在中国市场一直保持着高速增长，业务发展迅猛，对数据需求越来越高。但是在数据能力
                方面较为薄弱，系统数据不规范，手工数据繁多，传统EXCEL形式的企业报表和指标已经满足不了现有的分析需求。随着业务的快速发展，及时、可靠
                的数据获取和分析能力，以及完善、高效的报表和KPI管理，成为面临的重要问题。通过整合Dashboard业务指标依赖的多数据源以及设计企业数据仓
                库的架构与模型，从而实现复杂指标计算，更直观展示数据变化。</div>
        </div>
        <div class="head-list">
            <div class="head-list-item head-list-item-ischeck" style="margin-left:26.3rem"><span class="title">需求场景</span></div>
            <div class="head-list-item"><span class="title">实施方案</span></div>
            <div class="head-list-item"><span class="title">方实施收益</span></div>
        </div>
        <div class="container-1" style="display:flex">
            <div class="purchase-content-left">
                <div class="purchase-title">需求场景</div>
            </div>
            <div class="purchase-content-right">
                <div class="selected-box">
                    <div class="selected-card">
                        <div class="title1">业务发展迅猛，数据量庞大</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">数据能力薄弱，系统数据不规范</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">手工数据繁多，耗时耗力，人工成本巨大</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-2" style="display:flex;height: 55rem;">
            <div class="purchase-content-left">
                <div class="purchase-title">实施方案</div>
            </div>
            <div class="purchase-content-right">
                <div class="purchase-box">
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-5组件.svg"/>
                        <div>
                            <div class="title1">组件  </div>
                            <div class="title2">采用了亚马逊云科技大数据平台S3、Redshift、RDS、EC2等组件，在数据清洗、指标计算时，获得了较大的算力，数据时效性获得了极大的提升 </div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-5分析.svg"/>
                        <div>
                            <div class="title1">分析</div>
                            <div class="title2">通过全面分析来自客户市场、销售、品牌、经销商等业务分析需求，设计和实施数据模型和提供逐步实施的方法与建议</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-5自助分析.svg"/>
                        <div>
                            <div class="title1">自助分析</div>
                            <div class="title2">整合内部系统和外部手工数据，满足业务用户自定义获取和查询业务数据能力，完成自助分析</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-5tableau.svg"/>
                        <div>
                            <div class="title1">tableau进行报表设计与开发</div>
                            <div class="title2">通过业务部门选定的最关切的BI分析指标，借助tableau进行报表设计与开发，完成报表展示以及复杂指标计算</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-5数据清洗.svg"/>
                        <div>
                            <div class="title1">数据清洗转换</div>
                            <div class="title2">手工数据繁多，数据格式和内容不规则，通过数据清洗转换，有效提升数据质量，更好地服务于业务决策</div>
                        </div>
                    </div>
                    <div style="width:34.8rem;height:21rem">
                    </div>
                </div>
            </div>
        </div>
        <div class="container-1" style="display:flex">
            <div class="purchase-content-left">
                <div class="purchase-title">实施收益</div>
            </div>
            <div class="purchase-content-right">
                <div class="purchase-title" style="display:flex;height: 5rem;"><div class="purchase-doit">●</div><div class="special-style">数据平台设计与开发方便数据管理，帮助客户整合</div></div>
                <div class="purchase-title" style="display:flex;height: 5rem;"><div class="purchase-doit">●</div><div class="special-style">企业数据仓库的架构与模型使客户能支持需求场景的快速变化</div></div>
                <div class="purchase-title" style="display:flex;height: 5rem;"><div class="purchase-doit">●</div><div class="special-style">减少数据的复杂性，让客户能够轻松的提取和使用数据</div></div>
                <div class="purchase-title" style="display:flex;height: 5rem;"><div class="purchase-doit">●</div><div class="special-style">制作Dashboard，完成复杂指标计算，更直观展示数据变化%</div></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BizfocusPagesPurchaseCollaboration',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="less" scoped>
.special-style{
    height: 5rem;
    font-size: 1.6rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #333333;
    line-height: 3rem;
    display: flex;
    .title-6{
        text-align: center;
        margin: auto;
        width: 5rem;
        height: 5rem;
        font-size: 4rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #ED5847;
        line-height: 3rem;
        margin-top: -0.5rem;
    }
}
.purchase-content-left{
    width:33rem;
    text-align: left;
    margin-left: 26rem;
    margin-top: 5.9rem;

}
.purchase-title{
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Regular';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.purchase-content{
    height: 7.7rem;
    font-size: 1.6rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #666666;
    line-height: 2.6rem;

    margin: 1.3rem 0 0 0.2rem;
}
.purchase-doit{
    color: #612177;
    font-size: 1rem;
    transform: scale(0.8);
    margin-right: 1.4rem;
}
.purchase-content-right{
    text-align: left;
    margin-top: 5.9rem;
    padding-bottom: 2rem;
}
.solution-img{
    width: 107.2rem;
    height: 62.1rem;
}
.solution-img2{
    width: 107rem;
    height: 41.7rem;
    margin-top: 5rem;
}
.benefits-img{
    width: 80.5rem;
    height: 33.6rem;
}
.selected-box{
    width: 106.8rem;
    height: 20rem;
    display: flex;
    .selected-card{
        width: 35.6rem;
        height: 12rem;
        background: #FFFFFF;
        border: 1px solid #EDEDED;
        .title1{
            text-align: center;
            width: 29.2rem;
            height: 6rem;
            font-size: 16px;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: auto;
            margin-top: 3.5rem;
        }
    }
    .selected-card:hover{
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);
        border: 1px solid #612177;
    }
}
.purchase-box{
    width: 106rem;
    // height: 26.5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5rem;
    .card{
        width: 34.8rem;
        height: 21rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(219,219,219,0.5);
        border-radius: 2px;
        // justify-content: center;
        margin-top: 2rem;
        display: flex;
        .logo{
            width: 4rem;
            height: 4rem;
            margin: 3rem 0 0 2rem;
        }
        .title1{
            height: 2rem;
            width: 100%;
            font-size: 2rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: 3.3rem 0 0 1.2rem;
        }
        .title2{
            width: 25.2rem;
            height: 4.8rem;
            font-size: 1.6rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #666666;
            line-height: 2.4rem;

            margin: 0.8rem 0 0 1.2rem;
        }
    }
}
</style>
